.home-block {
    span {
        position: absolute;
        top: 10%;
        right: 10%;
        font-weight: $headings-font-weight;
        @include font-size($h3-font-size);
        font-family: $headings-font-family;
    }

    .home-block-btn {
        position: absolute;
        bottom: 5%;
        right: 10%;

        @extend .btn-primary;
    }
}