.footer {
    .footer-about {
        background-color: transparent;
        color: $gray-500;
        .footer-about {
            margin-bottom: map-get($spacers, 4);
            padding: map-get($spacers, 3) map-get($spacers, 4);
            border: $border-width solid darken($gray-100, 2.25);
        }
    }

    .footer-middle-wrapper {
        br {
            display: none;
        }

        .footer-block {
            .widget {
                a {
                    span {
                        font-size: $font-size-sm;
                    }
                }
            }
        }
    }
}
