header {
    nav {
        &.navbar {
            #navbar-offcanvas {
                .navbar-nav {
                    .menu-item {

                        &:last-child {
                            a {
                                span {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &.dropdown {
                            .menu-link-normal {
                                display: flex;
                                align-items: center;

                                span {
                                    &.fa-star {
                                        order: 2;

                                        &::after {
                                            @include svg-icon(0.875rem, 0.875rem, $icon-caret-down, $primary);
                                        }
                                    }

                                    &::after {
                                        @include svg-icon(0.875rem, 0.875rem, $icon-star, $secondary);
                                    }
                                }
                            }
                        }

                        a {
                            color: $gray-900;

                            span {
                                &::after {
                                    display: none;
                                }
                            }

                        }

                        &.dropdown {
                            a {
                                span {
                                    &::after {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
