$iconClasses: (star: $icon-star-sharp,
    gift: $icon-gift,
    heart: $icon-heart,
    award: $icon-award,
    truck: $icon-truck,
    thumbs-up: $icon-thumbs-up);

$iconWidth: 1rem;
$iconColor: $white;

@each $class,
$icon in $iconClasses {
    header {
        .top-header {
            .usp-block {
                .icon-#{$class} {
                    @include svg-icon($iconWidth, $iconWidth, $icon, $iconColor);
                    vertical-align: middle;
                }
            }
        }
    }
}

header {
    .top-header {
        justify-content: flex-start;

        .usp-block {
            .title {
                color: $white;
                font-family: $headings-font-family;
                font-size: $font-size-sm;
            }
        }

        .top-usp {
            margin-left: $spacer;
        }
    }

    .header {
        .header-sub {
            .logo-relative {
                .logo {
                    img {
                        top: -1.5rem;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
